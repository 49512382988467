import { augmentedOcassionsApi } from 'api'
import { CreativesDto } from 'types/dto/CreativesDto'

interface CreativesProps {
  tenantId: string
}

export const fetchCreatives = ({ tenantId }: CreativesProps) =>
  augmentedOcassionsApi.get<CreativesDto[]>('cco/creatives', {
    params: {
      tenantId,
    },
  })
