// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uYG0S{width:100%;height:100%;padding:0 24px 24px}.uYG0S::part(card){padding:24px}.mS1P6{display:grid;grid-gap:24px;grid-template-columns:repeat(4, 1fr)}.Ys3A8{display:flex;align-items:center;justify-content:space-between;width:100%;padding:24px}.d8Vqx{display:flex;align-items:center;justify-content:center;height:75vh}`, "",{"version":3,"sources":["webpack://./src/pages/sitecoreAssetsReview/SitecoreAssetsReview.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,mBAAA,CAEA,mBACE,YAAA,CAIJ,OACE,YAAA,CACA,aAAA,CACA,oCAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,YAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  padding: 0 24px 24px;\n\n  &::part(card) {\n    padding: 24px;\n  }\n}\n\n.items {\n  display: grid;\n  grid-gap: 24px;\n  grid-template-columns: repeat(4, 1fr);\n}\n\n.tool {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: 24px;\n}\n\n.dataLoader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 75vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `uYG0S`,
	"items": `mS1P6`,
	"tool": `Ys3A8`,
	"dataLoader": `d8Vqx`
};
export default ___CSS_LOADER_EXPORT___;
